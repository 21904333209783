import React from "react";

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
        <form
            className="contact--form"
            onSubmit={this.submitForm}
            action="https://formspree.io/xknqndza"
            method="POST"
        >
          <label className="form--label">
            First name *
            <input
                type="text"
                name="firstName"
                required
            />
          </label>
          <label className="form--label">
            Last name *
            <input
                type="text"
                name="lastname"
                required
            />
          </label>
          <label className="form--label">
            Company
            <input
                type="text"
                name="company"
            />
          </label>
          <label className="form--label">
            Email address *
            <input
                type="email"
                name="email"
                required
            />
          </label>
          <label className="form--label">
            Your message *
            <textarea
                name="message"
                required
            />
          </label>
          <div className="label-checkbox--wrapper">
            <div className="checkbox--wrapper">
              <input
                  type="checkbox"
                  name="GDPR"
                  id="GDPR-checkbox"
                  required
              />
              <span className="checkboxdummy"></span>
            </div>
            <label className="checkbox--label" htmlFor="GDPR-checkbox">I agree to my details being stored for contact purposes only.</label>
          </div>
          {status === "SUCCESS" ? <p>Thank you for your submission! I will contact you shortly!</p> : <button type="submit" value="Send">Contact me!</button>}
          {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}