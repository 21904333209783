import React, { Component } from 'react';
import Pagetitle from "../components/Pagetitle";
import Header from "../components/Header";
import Contactform from "../components/Contactform";
import Footer from "../components/Footer";
import '../styles/components/contactpage.scss';
import Bodytext from "../components/Bodytext";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

export default class ContactPage extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact me</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page">
          <Header />
          <div className="contact--page container">
            <Pagetitle pageTitle="Contact" />
            <Bodytext bodyText="Send me a message!" />
            <Contactform />
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}